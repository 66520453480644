import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  return (
    <div className="px-8 bg-black py-11">
      <div className="container mx-auto">
        <div className="flex flex-col items-center justify-center md:flex-row">
          <div className="w-full mb-3 md:w-1/4 md:mb-0">
            <StaticImage src="../images/bio.png" alt="SMR" />
          </div>
          <div className="w-full text-3xl leading-relaxed text-white md:px-10 md:w-1/2">
            <div>
            &quot;We are an international, multi-lingual collective of bold
            thinkers and creative problem solvers. We move fast, and we’re
            always looking ahead.”
            </div>
            <div className="mt-2 text-2xl font-display">
            - Melissa Rodriguez, Founder
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Bio
