import React from 'react';
import Marquee from './marquees/marquee';

const Marquees = () => {
   return (
      <div className='mt-20 mb-10 ' >
         <Marquee text="We know your audience.  We know where they are.  We know how to talk to them." reverse={false}></Marquee>
         <Marquee text="We know your audience.  We know where they are.  We know how to talk to them." reverse={true}></Marquee>
         <Marquee text="We know your audience.  We know where they are.  We know how to talk to them." reverse={false}></Marquee>
      </div>
   );
};

export default Marquees;