import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Square from "./weAre/square"

const WeAre = () => {
  return (
    <div className="flex flex-col md:justify-between md:-mt-10 md:pr-6 relative z-10 md:flex-row md:pl-[15%]">
      <div className="px-5 pb-6 md:w-[51%]">
        <div className="md:w-[83%]">
          <Square></Square>
        </div>
        <div className="text-[11vw] leading-normal mb-6 md:text-[2.4vw] md:pl-[calc(4vw+12px)] xl:mt-10">
          We are the trusted digital partner you need — we take your brand
          online and help you create digital relationships that matter.
        </div>
        <div className="mb-6 text-xl md:text-[1.389vw] leading-tight xl:mt-10">
          For brands who recognize the importance of winning with new
          Millennial, Gen Z, Hispanic audiences.
        </div>
        <a
          href="#contact"
          className="block w-full py-4 text-center xl:mt-12 text-white bg-black border-4 md:ml-[calc(4vw+12px)] border-black rounded-full md:w-[18vw] md:py-6 hover:bg-white hover:text-black"
        >
          Let's Talk
        </a>
      </div>
      <div className="md:w-[40%] md:mt-64 relative">
        <div className="relative  z-20  aspect-[464/940]">
          <StaticImage
            className="absolute z-20 w-full h-full "
            src="../images/statue.png"
            alt="SMR"
          />

          <div className="absolute z-10 hidden w-full h-full md:block -left-6 top-6">
            <StaticImage
              className="w-full h-full "
              src="../images/statue-blur.png"
              alt="SMR"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WeAre
