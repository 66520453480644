import React from "react"
import Arrow from "../svg/arrow.inline.svg"

const Services = () => {
  const buttonText = [
    {
      text: "Innovative Digital Brand Evolution",
      color: "gold"
    },
    {
      text: "Brand Voice & Digital Brand Strategy",
      color: "coral"
    },
    {
      text: "Messaging & Positioning",
      color: "pink"
    },
    {
      text: "Consumer driven content (Native)",
      color: "magenta"
    },
    {
      text: "Video, Audio, Visual Design",
      color: "orange"
    },
    {
      text: "High Impact Digital Campaigns",
      color: "gold"
    },
    {
      text: "Platform specific marketing campaigns",
      color: "coral"
    },
    {
      text: "Media Buying",
      color: "pink"
    },
    {
      text: "Influencer & Brand Ambassador",
      color: "magenta"
    },
    {
      text: "Public Relations",
      color: "orange"
    },
  ]

  return (
    <div className="px-6 py-10 md:py-20">
      <h2 className="hidden md:block text-center text-[8.611vw] tracking-tight font-display leading-tight w-full mb-12">
        <div className="block ">Our Services & What</div>
        <div className="block ">We Can Do For You</div>
      </h2>
      <h2 className="md:hidden text-[19.211vw] tracking-tight font-display leading-tight w-full mb-6">
        Our Services & What We Can Do For You
      </h2>
      <div className=" md:text-center md:px-20">
        {buttonText.map((e, i) => {
          return (
            <button className="mb-4 md:mb-6 hover:pr-16 group  items-center justify-center relative  bg-gold inline-block rounded-full md:mx-4  text-black text-[3.467vw] md:text-[1.667vw] z-10 py-6 px-6 border-4 border-black transition-all shadow-[6px_6px] md:shadow-[10px_10px] hover:shadow-[16px_16px] hover:-ml-6">
              #{e.text}
              <div className="absolute hidden transition-opacity -translate-y-1/2 opacity-0 group-hover:block group-hover:opacity-100 right-6 top-1/2">
              <Arrow></Arrow>
              </div>
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default Services
