import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import OpeningVideo from "../components/openingVideo"
import WeAre from "../components/weAre"
import Marquees from "../components/marquees"
import Badges from "../components/badges"
import Services from "../components/services"
import ContactForm from "../components/form"
import Bio from "../components/bio"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <OpeningVideo></OpeningVideo>
    <WeAre></WeAre>
    <Marquees></Marquees>
    <Badges></Badges>
    <Services></Services>
    <Bio></Bio>
    <ContactForm></ContactForm>
  </Layout>
)

export default IndexPage
