import React from "react"
import Dots from "../../svg/dots.inline.svg"

const Square = () => {
  return (
    <>
      <div className="bg-pink border-white  mb-10 py-10 pl-[12vw] relative  leading-tight border-[12px] shadow-xl	md:aspect-square pl-[4vw]">
        <div className="absolute z-20 w-[11.5vw] md:w-[3.5vw] text-black top-3 right-3">
          <Dots className="fill-current"></Dots>
        </div>
        <div className="text-[18vw] md:text-[5.8vw] font-display tracking-tight w-[115%]">
          We’re
          <br /> Social Media Relations
        </div>

        <div className="pt-6 text-xl">But you can call us SMR.</div>
      </div>
    </>
  )
}

export default Square
