import React from "react"

const Badges = () => {
  const badgesOfHonor = [
    {
      title: "Designrush",
      award: "Accredited Agency",
      year: "2021",
    },
    {
      title: "Upcity",
      award: "Best of Creative Design",
      year: "Winner 2021",
    },
    {
      title: "Upcity",
      award: "Best of Marketing & Advertising",
      year: "Winner 2021",
    },
    {
      title: "Clutch Los Angeles",
      award: "Top Advertising & Marketing Agencies",
      year: "2021",
    },
    {
      title: "Hispanic Star",
      award: "",
      year: "",
    },
  ]

  return (
    <div className="py-6 md:py-12 border border-black text-white bg-magenta border-y-[20px]">
      <h2 className="text-center hidden md:block text-[8.611vw] tracking-tight font-display w-full mb-4">
        Our Badges of Honor
      </h2>
      <h2 className="md:hidden px-6 text-[19.211vw] tracking-tight leading-tight font-display w-full mb-4">
        Our
        <br />
        Badges of
        <br />
        Honor
      </h2>
      <div className="px-6 md:text-center">
        {badgesOfHonor.map((e, i) => {
          return (
            <div className="mb-3 text-[5.211vw] md:text-[2.211vw] leading-tight ">
              {e.title} {e.award} {e.year}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Badges
