import React, { useState, useEffect, useRef } from "react"

const Marquee = ({ reverse, text }) => {
  const marqueeRef = useRef(null)
  const [initW, setInitW] = useState(null);
  // const [iterations, setIterations] = useState(1)
  const [textString, setTextString] = useState(text)

  useEffect(() => {
    if(initW) {
      console.log("w", initW)
      const iterations = Math.ceil(window.innerWidth / initW)  * 2;
      let finalText = '';
      for (let index = 0; index < iterations; index++) {
        finalText = `${text} ${finalText}`;
      }
      setTextString(finalText);
    }
    else {

      setInitW(marqueeRef.current.offsetWidth)
    }

  }, [initW, text])

  return (
    <div className="w-full overflow-hidden text-xs font-bold uppercase">
      <div
        className="whitespace-nowrap inline-block  bg-gradient-to-r from-[#f499e8] via-[#babff1] to-[#53d8f7] bg-clip-text "
        style={{
          animation: reverse
            ? "ticker-reverse 30s linear infinite"
            : "ticker 30s linear infinite",
            "-webkit-text-fill-color": "transparent"
        }}
        ref={marqueeRef}
      >
       { textString }

      </div>
    </div>
  )
}

export default Marquee
