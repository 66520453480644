import React, { useState } from "react"

const ContactForm = () => {
  const [message, setMessage] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [topic, setTopic] = useState("")
  const [message2, setMessage2] = useState("")
  const [botField, setBotField] = useState("")

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = event => {
    event.preventDefault()
    fetch("https://equasar.com/api/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        "name": name,
        "email": email,
        "topic": topic,
        "message": message2,
        "bot-field": botField
      }),
    })
      .then(() => {
        setMessage(true)
        setName("")
        setEmail("")
        setTopic("")
        setMessage2("")
      })
      .catch(error => alert(error))
  }

  return (
    <div id="contact" className="pb-20">
      <h2 className="text-[19.211vw] md:text-[8.611vw] tracking-tight text-center font-display leading-tight w-full mb-4">
        Questions?
      </h2>
      <div className="mb-12 text-2xl text-center">You know the drill</div>

      <form
        name="contact"
        method="POST"
        data-netlify="true"
        netlify
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        className="w-full px-6 md:px-0 md:w-[34.7%] mx-auto"
      >
        <input type="hidden" name="form-name" value="contact" />

        {message && (
          <div className="mb-6 text-xl text-center">
            Thank you for contacting us! We'll be in touch.
          </div>
        )}

        <p className="hidden">
    <label>Don’t fill this out if you’re human: <input name="bot-field" value={botField} onChange={e => setBotField(e.target.value)} /></label>
  </p>

        <input
          type="text"
          placeholder="Your Name"
          className="w-full p-6 mb-4 text-xl text-black border-4 border-black rounded-full placeholder-gray"
          name="name"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          className="w-full p-6 mb-4 text-xl text-black border-4 border-black rounded-full placeholder-gray"
        />
        <select
          className="w-full p-6 mb-4 text-xl text-black bg-white border-4 border-black rounded-full placeholder-gray"
          name="topic"
          value={topic}
          onChange={e => setTopic(e.target.value)}
        >
          <option value="">Select a Topic</option>
          <option value="New Business">New Business</option>
          <option value="Careers">Careers</option>
          <option value="Press">Press</option>
          <option value="Speaking">Speaking</option>
        </select>
        <textarea
          name="message"
          placeholder="Your Message"
          id=""
          class="w-full p-6 mb-4 text-xl text-black border-4 border-black rounded-xl placeholder-gray"
          cols="30"
          rows="10"
          value={message2}
          onChange={e => setMessage2(e.target.value)}
        ></textarea>

        <input
          type="submit"
          name="submit"
          value="Send it"
          className="block w-full py-4 text-center text-white bg-black border-4 border-black rounded-full hover:bg-white hover:text-black"
        />
      </form>
    </div>
  )
}

export default ContactForm
