import React from 'react';
import OpeningVideoSrc from "../video/smr.mp4"

const OpeningVideo = () => {
   return (
      <div className='w-full h-screen md:aspect-video relative'>
         <video src={OpeningVideoSrc} className="w-full h-full absolute object-cover" playsInline loop autoPlay muted noControls></video>
      </div>
   );
};

export default OpeningVideo;